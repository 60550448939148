import { useState, useEffect, useRef } from 'react';
import './AgendaNavigationMobile.scss';
import clock from '../../assets/clock.png';
import moment from 'moment';
import { PdfPreview } from '../../components/PdfPreview/PDFPreview';
import useFetchOneEvent from '../../customHooks/FetchOneEvent/FetchOneEvent';
import { currentEventId } from '../../config/config';
import { getAgendaTemplate } from '../../document-templates/AgendaTemplate';
import parse from 'html-react-parser';

const AgendaNavigationMobile = () => {
  const [activeAccordion, setActiveAccordion] = useState<any>(undefined);
  const [idEvent, setIdEvent] = useState<string>(currentEventId);
  const [oneEvent, setOneEvent] = useState<any>();
  const [agendaNavTabs, setAgendaNavTabs] = useState<any>();
  const [agendasData, setAgendasData] = useState<any>([]);
  const [dataEvent] = useFetchOneEvent(idEvent);
  const [getData, setGetData] = useState(false);

  const [pdfData, setPdfData] = useState<any>(
    getAgendaTemplate(
      dataEvent?.agendas ?? [],
      '8. SRPSKI DANI OSIGURANJA',
      'HOTEL ZLATIBOR',
      new Date(2023, 10, 26),
      new Date(2023, 10, 29)
    )
  );

  const downloadPdfDocument = useRef(null);

  const sortData = agendasData[activeAccordion - 1]?.sort(({ time: a }: any, { time: b }: any) =>
    a > b ? 1 : a < b ? -1 : 0
  );

  const handleShowFirstAccordion = (active: any) => {
    return setActiveAccordion(active);
  };

  useEffect(() => {
    setOneEvent(dataEvent);
    setPdfData(
      getAgendaTemplate(
        dataEvent?.agendas ?? [],
        '8. SRPSKI DANI OSIGURANJA',
        'HOTEL ZLATIBOR',
        new Date(2023, 10, 26),
        new Date(2023, 10, 29)
      )
    );
  }, [dataEvent]);

  useEffect(() => {
    if (oneEvent !== undefined) {
      const dateArray = [];
      let currentDate = moment(oneEvent.dateFrom);
      const stopDate = moment(oneEvent.dateTo);
      while (currentDate <= stopDate) {
        dateArray.push({ date: moment(currentDate).format('DD.MM.YYYY.') });
        currentDate = moment(currentDate).add(1, 'days');
      }
      const filterArray = dateArray.map((el: any) => {
        return [];
      });
      setAgendasData(filterArray);
      setAgendaNavTabs(dateArray);
      setGetData(true);
    }
  }, [oneEvent]);

  useEffect(() => {
    if (getData) {
      let newData: any = [...agendasData];
      agendaNavTabs.forEach((tab: any, i: number) => {
        oneEvent.agendas.forEach((el: any) => {
          const agendaDate = moment(el.date).format('DD.MM.YYYY.');
          const tabDate = tab.date;
          if (agendaDate === tabDate) {
            const mappedData = newData.map((el1: any, i1: any) => {
              if (i1 === i) {
                return [...el1, el];
              } else {
                return el1;
              }
            });
            return (newData = mappedData);
          }
        });
      });
      setAgendasData(newData);
      setActiveAccordion(1);
      setGetData(false);
    }
  }, [getData]);

  return (
    <div className="agenda-navigation-mobile">
      <p className="title">Agenda</p>
      <p className="sub-title">8. SRPSKI DANI OSIGURANJA</p>

      <div className="description">
        <p className="description-title">Agenda u pripremi.</p>
      </div>
      <div className="accordion-box-mobile">
        {agendaNavTabs?.map((el: any, i: number) => {
          return (
            <div className="row" key={i}>
              <div className="accordion-input">
                <div
                  onClick={() => {
                    handleShowFirstAccordion(i + 1);
                  }}
                  className={activeAccordion === i + 1 ? 'active-mobile' : 'accordion-item-mobile'}>
                  <p className="title">{`DAN ${i + 1} -`}</p>
                  <p className="accordion-item-date">{`${el.date}`}</p>
                </div>
              </div>
              <div className={activeAccordion === i + 1 ? 'accordion-body' : 'close'}>
                {sortData?.length === 0 ? (
                  <p className="blue-text">U pripremi</p>
                ) : (
                  <div>
                    {sortData?.map((el: any, i: number) => {
                      if (el.block) {
                        return (
                          <div key={i} className="data-title">
                            {moment(el.time[0]).format('HH:mm')}{' '}
                            <div className="data-title-text">{parse(el.content)}</div>
                          </div>
                        );
                      } else {
                        return (
                          <div key={i} className="data-row">
                            <div className="left-container">
                              <img className="img" src={clock} />
                              <p className="time">{moment(el.time[0]).format('HH:mm')}</p>
                              <div className="text">{parse(el.content)}</div>
                            </div>
                          </div>
                        );
                      }
                    })}
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </div>

      <div className="btn-container">
        <button
          className="btn"
          onClick={() => {
            if (downloadPdfDocument.current) {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              downloadPdfDocument.current();
            }
          }}>
          PREUZMITE PDF AGENDU
        </button>
        <div className="agenda-container">
          <PdfPreview
            pdfDefinition={pdfData}
            downloadDocument={downloadPdfDocument}
            documentName={'Agenda.pdf'}
          />
        </div>
      </div>
    </div>
  );
};

export default AgendaNavigationMobile;
